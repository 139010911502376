import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import moment from "moment"

import { func } from "prop-types"
import ImageMeta from "../ImageMeta"
import SocialIcon from "./SocialIcon"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"

var classNames = require("classnames")
var widont = require("widont")

export default class ComFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.toggleFooter = this.toggleFooter.bind(this)
  }

  toggleFooter() {}

  render() {
    var comfooterClass = classNames({
      "com-footer": true,
      "color-back": true
    })

    return (
      <StaticQuery
        query={graphql`
          query footerMyQuery {
            allDataJson(filter: { optionsItem: { eq: "siteVariables" } }) {
              nodes {
                siteItems {
                  locations {
                    location {
                      locationName
                      stateAbbr
                      googlePlaceLink
                      streetNumber
                      streetName
                      cityZip
                      cityName
                      stateName
                      layout
                      metaTitle
                      metaDescription
                      title
                      searchHeading
                      contactInfo {
                        email
                        phone
                      }
                      locationHours {
                        endTime
                        startTime
                        weekDay
                      }
                      socialAccounts {
                        socialAcount
                        socialAcountLink
                      }
                      extraLinks {
                        statements {
                          linkText
                          pageLink
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
            footerJson {
              footerItems {
                blurbText
                logo
              }
            }
            site {
              siteMetadata {
                fullPracticeName
              }
            }
          }
        `}
        render={data => {
          let siteMetadata = data.site.siteMetadata

          let locationOne =
            data.allDataJson.nodes[0].siteItems.locations[1].location
          let locationTwo =
            data.allDataJson.nodes[0].siteItems.locations[0].location

          let footerItems = data.footerJson.footerItems

          return (
            <footer
              id={this.props.pageTitle}
              className={comfooterClass + " " + this.props.pageTitle}
            >
              <div className="columns is-desktop footer-top">
                <div className="column is-12 footer-left">
                  <div className="columns is-centered is-vcentered">
                    <div className="column is-2"></div>
                    <div className="column is-4 footer-logo">
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={footerItems.logo}
                        width="auto"
                        noLazyload
                        responsive
                      ></ImageMeta>
                    </div>
                    <div className="column is-2"></div>
                    <div className="column">
                      <h5 className="footer-blurb-text">
                        {widont(footerItems.blurbText)}
                      </h5>
                    </div>
                    <div className="column is-2"></div>
                  </div>
                </div>
                <div className="column footer-right">
                  <div className="columns footer-right-top">
                    <div className="column">
                      <h7>
                        {this.props.language === "es"
                          ? "Ubicación"
                          : "East Amarillo"}
                      </h7>
                      <p className="small footer-location-address">
                        <a
                          aria-label="open link in new browser tab"
                          target="_blank"
                          href={locationOne.googlePlaceLink}
                          title={locationOne.locationName}
                        >
                          {locationOne.streetNumber + " "}
                          {locationOne.streetName}
                          <br />
                          {locationOne.cityName}
                          {", " + locationOne.stateAbbr + " "}
                          {locationOne.cityZip}
                        </a>
                      </p>

                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={locationOne.locationHours}
                        language={this.props.language}
                      />
                    </div>
                    <div className="column">
                      <h7>
                        {this.props.language === "es"
                          ? "Ubicación"
                          : "West Amarillo"}
                      </h7>
                      <p className="small footer-location-address">
                        <a
                          aria-label="open link in new browser tab"
                          target="_blank"
                          href={locationTwo.googlePlaceLink}
                          title={locationTwo.locationName}
                        >
                          {locationTwo.streetNumber + " "}
                          {locationTwo.streetName}
                          <br />
                          {locationTwo.cityName}
                          {", " + locationTwo.stateAbbr + " "}
                          {locationTwo.cityZip}
                        </a>
                      </p>

                      <GeneralHours
                        className="is-centered"
                        footer={true}
                        locationHours={locationTwo.locationHours}
                        language={this.props.language}
                      />
                    </div>
                  </div>
                  <div className="columns footer-right-bottom">
                    <div className="column footer-contact">
                      <h7>
                        {this.props.language === "es"
                          ? "Charlemos"
                          : "Let's Chat"}
                      </h7>

                      <p className="small footer-location-address">
                        East Amarillo:{" "}
                        <a
                          href={"tel:+" + locationOne.contactInfo.phone}
                          title="Call us"
                        >
                          {locationOne.contactInfo.phone}
                        </a>
                      </p>

                      <p className="small footer-location-address">
                        West Amarillo:{" "}
                        <a
                          href={"tel:+" + locationTwo.contactInfo.phone}
                          title="Call us"
                        >
                          {locationTwo.contactInfo.phone}
                        </a>
                      </p>

                      <p className="small">
                        <a
                          href={`mailto:${locationOne.contactInfo.email}`}
                          title="Email us"
                        >
                          {locationOne.contactInfo.email}
                        </a>
                      </p>
                      {locationOne.contactInfo.chatLink && (
                        <p className="small">
                          <Link
                            to={
                              this.props.language === "es"
                                ? "/contact-us-amarillo-tx/"
                                : "/contact-us/"
                            }
                          >
                            {this.props.language === "es"
                              ? "Mandanos un Mensaje"
                              : locationOne.contactInfo.chatLink}
                          </Link>
                        </p>
                      )}
                    </div>
                    <div className="column footer-social">
                      <div className="column">
                        <h7 style={{ marginBottom: "8px" }}>
                          {this.props.language === "es" ? "Sociales" : "Social"}
                        </h7>
                      </div>
                      <div className="columns">
                        {locationOne.socialAccounts &&
                          locationOne.socialAccounts.map((item, i) => (
                            <React.Fragment key={i}>
                              <p
                                className={`small social-icon ${item.socialAcount}`}
                              >
                                <a
                                  aria-label="open link in new browser tab"
                                  href={item.socialAcountLink}
                                  target="_blank"
                                  title={item.socialAcount}
                                >
                                  <SocialIcon
                                    fillColor="#ffffff"
                                    socialAccount={item.socialAcount}
                                  />
                                </a>
                              </p>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-2"></div>
              </div>

              <div className="columns is-desktop footer-bottom is-vcentered">
                <div className="column is-4"></div>
                <div className="column is-7 sub-footer-copyright">
                  <p className="footer">
                    © {moment().format("YYYY")} {siteMetadata.fullPracticeName}.
                    All rights reserved.
                  </p>
                  <p
                    className="nuvo-brand footer"
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.language === "es"
                          ? 'Sitio Web y Producción de Video por <a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Nuvolum</a>'
                          : 'Website and Video Production by <a href="https://nuvolum.com" target="_blank" style="font-weight: bold" title="Website and Video Production by Nuvolum">Nuvolum</a>'
                    }}
                  ></p>
                </div>
                <div className="column is-1"></div>
                <div className="column">
                  <div className="columns sub-footer-statements">
                    {this.props.language !== "es" &&
                      locationOne.extraLinks &&
                      locationOne.extraLinks[0].statements.map(item => (
                        <div key={item.id} className="column is-narrow">
                          <p className="footer">
                            <Link to={`/${item.pageLink}/`} title={item.title}>
                              {item.linkText}
                            </Link>
                          </p>
                        </div>
                      ))}
                    {this.props.language === "es" &&
                      locationOne.extraLinks &&
                      locationOne.extraLinks[0].statements.map(item => (
                        <React.Fragment key={item.id}>
                          <p className="footer">
                            <Link
                              to={`/es/${item.pageLink}/`}
                              title={item.title}
                            >
                              {item.linkText}
                            </Link>
                          </p>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div className="column is-2"></div>
              </div>
            </footer>
          )
        }}
      />
    )
  }
}
