import React from "react"
import PropTypes from "prop-types"

import Dots from "./Dots"

import ThreeLines from "./ThreeLines"

var classNames = require("classnames")

class HamburgerIcon extends React.Component {
  constructor(props) {
    super(props)
  }

  renderIcon(iconType) {
    var HamburgerIconSpanClass = classNames({
      open: this.props.isOpen && !this.props.isClosing
    })
    switch (iconType) {
      case "twoLines":
        return (
          <>
            <span className={HamburgerIconSpanClass}></span>
            <span className={HamburgerIconSpanClass}></span>
          </>
        )
      case "threeLines":
        if (this.props.isOpen && !this.props.isClosing) {
          return (
            <>
              <span className={HamburgerIconSpanClass}></span>
              <span className={HamburgerIconSpanClass}></span>
            </>
          )
        } else {
          return <ThreeLines stroke="#6d6e6f" />
        }
      case "dots":
        if (this.props.isOpen && !this.props.isClosing) {
          return (
            <>
              <span className={HamburgerIconSpanClass}></span>
              <span className={HamburgerIconSpanClass}></span>
            </>
          )
        } else {
          return <Dots fill="#6d6e6f" />
        }
      case "word":
        var menuWordClass = classNames({
          "menu-word": true,
          bold: this.props.boldMenu
        })
        if (this.props.isOpen && !this.props.isClosing) {
          return (
            <>
              <span className={HamburgerIconSpanClass}></span>
              <span className={HamburgerIconSpanClass}></span>
            </>
          )
        } else {
          return <div className={menuWordClass}>menu</div>
        }
    }
  }

  render() {
    var cMenuClass = classNames({
      "c-menu":
        this.props.iconType !== "word" ||
        (this.props.isOpen && !this.props.isClosing),
      "menu-word-adjust": this.props.iconType === "word" && this.props.isOpen,
      disabled: this.props.isOpening || this.props.isClosing
    })

    return (
      <div
        className={cMenuClass}
        onClick={
          this.props.isOpening || this.props.isClosing
            ? false
            : this.props.toggleMenu
        }
      >
        {this.renderIcon(this.props.iconType)}
      </div>
    )
  }
}
HamburgerIcon.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  toggleMenu: PropTypes.func
}
export default HamburgerIcon
